import axios from 'axios';
// import Barcode from '../assets/images/templates/one-barcode.png';
import { DPI, multiPageLetters, Barcode } from './constants';
import {
  getViewProof,
  getViewProofForAdminOrder,
  getViewProofForOrder,
} from '../redux/actions/template-builder';
import { success, failure } from '../redux/actions/snackbar-actions';

export const getFileAsBlob = async (url, returnType = 'json') => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
    });
    return returnType === 'json'
      ? blobToJSON(response.data)
      : blobToString(response.data);
  } catch (error) {
    throw error; // Optionally rethrow the error for further handling
  }
};

const blobToJSON = (jsonBlob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Define a callback function for when the FileReader finishes reading
    reader.onload = function () {
      try {
        // Parse the result as JSON
        const parsedData = JSON.parse(reader.result);

        // Resolve the promise with the parsed JSON data
        resolve(parsedData);
      } catch (error) {
        // Reject the promise with the error
        reject(error);
      }
    };

    // Define a callback function for when there is an error reading the Blob
    reader.onerror = function (error) {
      // Reject the promise with the error
      reject(error);
    };

    // Start reading the Blob as text
    reader.readAsText(jsonBlob);
  });
};

export const blobToString = (blob) => {
  return new Promise((resolve, reject) => {
    const blobObject = new Blob([blob], { type: 'text/plain' });
    const reader = new FileReader();

    // Define a callback function for when the reading is complete
    reader.onloadend = function () {
      // The result property contains the data as a string
      const blobString = reader.result;

      // Resolve the Promise with the blobString
      resolve(blobString);
    };

    // Define a callback function for when an error occurs
    reader.onerror = function (error) {
      // Reject the Promise with the error
      reject(error);
    };

    // Start reading the content of the Blob as text
    reader.readAsText(blobObject);
  });
};

export const addressPrinting = {
  'Postcards-': true,
  'Tri-Fold Self-Mailers-': true,
  'Bi-Fold Self-Mailers-': true,
  'Professional Letters-#10 Double-Window': true,
};

export const exportPdfViewProofWithDummyData = async (store, title, fields) => {
  const json = store.toJSON();
  let jsonString = JSON.stringify(json);
  fields.forEach(({ key, defaultValue, value }) => {
    const regex = new RegExp(key, 'g');
    jsonString = jsonString.replace(regex, defaultValue || value);
  });
  const jsonWithDummyData = JSON.parse(jsonString);
  store.loadJSON(jsonWithDummyData);
  await store.waitLoading();
  await store.saveAsPDF({ fileName: title + '.pdf', pixelRatio: 2 });
  store.loadJSON(json);
};

export const toggleFigure = (store, key, value) => {
  store.getElementById(key).set({ visible: value });
};

export const isValidHtmlContent = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.childElementCount !== 0;
};

export const extractVariablesFromHtml = (html) => {
  const placeholderRegex =
    /\{\{\s*([A-Za-z_][A-Za-z0-9_]*(?:\.[A-Za-z_][A-Za-z0-9_]*)*)\s*\}\}/g;

  // Extract matches from the HTML string
  const matches = html.match(placeholderRegex);

  // Log the matches
  if (!matches) return [];
  var regex = /{{(.*?)}}/;
  return matches.map((item) => {
    const matchArray = item.match(regex);
    if (matchArray && matchArray[1]) {
      const [fullMatch, variable] = matchArray;
      return {
        key: fullMatch,
        value: variable,
        defaultValue: variable,
      };
    } else {
      return {
        key: item,
        value: item,
        defaultValue: item,
      };
    }
  });
};

export const htmlWithDummyData = (html, fields) => {
  let htmlString = html;
  fields.forEach(({ key, defaultValue, value }) => {
    const regex = new RegExp(key, 'g');
    htmlString = htmlString.replace(regex, defaultValue || value);
  });
  return htmlString;
};

export const multiPageTemplates = [
  'Postcards',
  'Tri-Fold Self-Mailers',
  'Bi-Fold Self-Mailers',
];
export const envelopeTypes = [
  { id: 1, title: 'Windowed Envelope', type: '#10 Double-Window' },
  { id: 2, title: 'Non-Windowed Envelope', type: '#10 Grey' },
];

const addAreaToProfessionalLetters = (store, barcodeSrc) => {
  const page = store.pages[0];
  const position = [0.6, 0.84];
  const position1 = [0.63, 1.71];
  page.addElement({
    id: 'figure-2',
    type: 'figure',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: 0.63 * DPI,
    y: 0.5 * DPI,
    width: 3.25 * DPI,
    height: 0.875 * DPI,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    subType: 'rect',
    fill: 'white',
    dash: [],
    strokeWidth: 0,
    stroke: '#0c0c0c',
    cornerRadius: 0,
  });
  page.addElement({
    id: 'figure-3',
    type: 'figure',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: 0.63 * DPI,
    y: 1.71 * DPI,
    width: 4 * DPI,
    height: 1 * DPI,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    subType: 'rect',
    fill: 'white',
    dash: [],
    strokeWidth: 0,
    stroke: '#0c0c0c',
    cornerRadius: 0,
  });
  page.addElement({
    id: 'return-address',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: position[0] * DPI + 10,
    y: position[1] * DPI - 10,
    width: 200,
    height: 18,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: 'YOUR RETURN ADDRESS \nWILL AUTOMATICALLY \nBE PRINTED IN THIS \nPOSITION',
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });

  page.addElement({
    id: 'barcode',
    type: 'image',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: position1[0] * DPI + 10,
    y: position1[1] * DPI + 70,
    width: 215,
    height: 12,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    src: barcodeSrc,
    cropX: 0,
    cropY: 0,
    cropWidth: 1,
    cropHeight: 1,
    cornerRadius: 0,
    flipX: false,
    flipY: false,
    clipSrc: '',
    borderSize: 0,
    keepRatio: false,
  });

  page.addElement({
    id: 'recipient-address',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: position1[0] * DPI + 10,
    y: position1[1] * DPI + 10,
    width: 165,
    height: 21,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: 'RECIPIENT ADDRESS \nWILL AUTOMATICALLY \nBE PRINTED IN THIS \nPOSITION',
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });

  page.addElement({
    id: 'sequence',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: position1[0] * DPI + 280,
    y: position1[1] * DPI + 64,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `Sequence`,
    placeholder: '',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'contId',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: position1[0] * DPI + 280,
    y: position1[1] * DPI + 74,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `Cont ID`,
    placeholder: '',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  store.history.clear();
};
const addRestrictedAreaToPostCard = (
  store,
  size,
  barcodeSrc,
  isPostCard = false,
) => {
  const page = store.pages[1];
  page.addElement({
    id: 'figure-1',
    type: 'figure',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI,
    y: store.height - size[1] * DPI - 0.125 * DPI,
    width: size[0] * DPI,
    height: size[1] * DPI,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    subType: 'rect',
    fill: 'white',
    dash: [],
    strokeWidth: 0,
    stroke: '#0c0c0c',
    cornerRadius: 0,
  });

  page.addElement({
    id: 'return-address',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI + 10,
    y: store.height - size[1] * DPI - 0.125 * DPI + 20,
    width: 240,
    height: 20,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: 'YOUR RETURN ADDRESS \nWILL AUTOMATICALLY \nBE PRINTED IN THIS \nPOSITION',
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'indicia',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI,
    y: store.height - size[1] * DPI + 10,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `POSTAGE\nINDICIA`,
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });

  page.addElement({
    id: 'barcode',
    type: 'image',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    y: store.height - 12 - size[1] * DPI + 110,
    x: store.width - size[0] * DPI - 0.15 * DPI + 40,
    width: size[0] * DPI - 80,
    height: 12,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    src: barcodeSrc,
    cropX: 0,
    cropY: 0,
    cropWidth: 1,
    cropHeight: 1,
    cornerRadius: 0,
    flipX: false,
    flipY: false,
    clipSrc: '',
    borderSize: 0,
    keepRatio: false,
  });

  page.addElement({
    id: 'recipient-address',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI + 10,
    y: store.height - size[1] * DPI - 0.125 * DPI + 150,
    width: 240,
    height: 20,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: 'RECIPIENT ADDRESS \nWILL AUTOMATICALLY \nBE PRINTED IN THIS \nPOSITION',
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'sequence',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI,
    y: store.height - size[1] * DPI + 170,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `Sequence`,
    placeholder: '',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'contId',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI,
    y: store.height - size[1] * DPI + 180,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `Cont ID`,
    placeholder: '',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  store.history.clear();
};

export const addRestrictedAreaToTriFold = (store, size, barcodeSrc) => {
  const page = store.pages[0];
  page.addElement({
    id: 'figure-1',
    type: 'figure',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI,
    y: store.height - size[1] * DPI - 0.125 * DPI - 4 * DPI,
    width: size[0] * DPI,
    height: size[1] * DPI,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    subType: 'rect',
    fill: 'white',
    dash: [],
    strokeWidth: 0,
    stroke: '#0c0c0c',
    cornerRadius: 0,
  });

  page.addElement({
    id: 'return-address',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI + 10,
    y: store.height - size[1] * DPI - 0.125 * DPI + 20 - 4 * DPI,
    width: 240,
    height: 20,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: 'YOUR RETURN ADDRESS \nWILL AUTOMATICALLY \nBE PRINTED IN THIS \nPOSITION',
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'indicia',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI,
    y: store.height - size[1] * DPI + 10 - 4 * DPI,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `POSTAGE\nINDICIA`,
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });

  page.addElement({
    id: 'barcode',
    type: 'image',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    y: store.height - 12 - size[1] * DPI + 110 - 4 * DPI,
    x: store.width - size[0] * DPI - 0.15 * DPI + 40,
    width: size[0] * DPI - 80,
    height: 12,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    src: barcodeSrc,
    cropX: 0,
    cropY: 0,
    cropWidth: 1,
    cropHeight: 1,
    cornerRadius: 0,
    flipX: false,
    flipY: false,
    clipSrc: '',
    borderSize: 0,
    keepRatio: false,
  });

  page.addElement({
    id: 'recipient-address',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI + 10,
    y: store.height - size[1] * DPI - 0.125 * DPI + 150 - 4 * DPI,
    width: 240,
    height: 20,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: 'RECIPIENT ADDRESS \nWILL AUTOMATICALLY \nBE PRINTED IN THIS \nPOSITION',
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'sequence',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI,
    y: store.height - size[1] * DPI + 170 - 4 * DPI,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `Sequence`,
    placeholder: '',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'contId',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI,
    y: store.height - size[1] * DPI + 180 - 4 * DPI,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `Cont ID`,
    placeholder: '',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  store.history.clear();
};

const addRestrictedAreaToBiFold = (store, size, barcodeSrc) => {
  const page = store.pages[0];
  page.addElement({
    id: 'figure-1',
    type: 'figure',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI - 9 * DPI,
    y: store.height - size[1] * DPI - 0.125 * DPI,
    width: size[0] * DPI,
    height: size[1] * DPI,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    subType: 'rect',
    fill: 'white',
    dash: [],
    strokeWidth: 0,
    stroke: '#0c0c0c',
    cornerRadius: 0,
  });

  page.addElement({
    id: 'return-address',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI + 10 - 9 * DPI,
    y: store.height - size[1] * DPI - 0.125 * DPI + 20,
    width: 240,
    height: 20,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: 'YOUR RETURN ADDRESS \nWILL AUTOMATICALLY \nBE PRINTED IN THIS \nPOSITION',
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'indicia',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI - 9 * DPI,
    y: store.height - size[1] * DPI + 10,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `POSTAGE\nINDICIA`,
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });

  page.addElement({
    id: 'barcode',
    type: 'image',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    y: store.height - 12 - size[1] * DPI + 110,
    x: store.width - size[0] * DPI - 0.15 * DPI - 9 * DPI + 40,
    width: size[0] * DPI - 80,
    height: 12,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    src: barcodeSrc,
    cropX: 0,
    cropY: 0,
    cropWidth: 1,
    cropHeight: 1,
    cornerRadius: 0,
    flipX: false,
    flipY: false,
    clipSrc: '',
    borderSize: 0,
    keepRatio: false,
  });

  page.addElement({
    id: 'recipient-address',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - size[0] * DPI - 0.15 * DPI + 10 - 9 * DPI,
    y: store.height - size[1] * DPI - 0.125 * DPI + 150,
    width: 240,
    height: 20,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: 'RECIPIENT ADDRESS \nWILL AUTOMATICALLY \nBE PRINTED IN THIS \nPOSITION',
    placeholder: '',
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'sequence',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI - 9 * DPI,
    y: store.height - size[1] * DPI + 170,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `Sequence`,
    placeholder: '',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  page.addElement({
    id: 'contId',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: false,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: store.width - 111 - 0.15 * DPI - 9 * DPI,
    y: store.height - size[1] * DPI + 180,
    width: 111,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: false,
    styleEditable: false,
    text: `Cont ID`,
    placeholder: '',
    fontSize: 8,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'black',
    align: 'center',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.2,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  store.history.clear();
};

export const createViewProof = (title, id) => async (dispatch) => {
  const response = await getViewProof(id);
  const binaryData = atob(response.data.data.base64Pdf);

  if (response.status === 200) {
    // Create a Uint8Array from the binary data
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    // Create an Object URL for the Blob
    const url = URL.createObjectURL(blob);
    downloadPDF(title, url);
    dispatch(success('Download Proof generated successfully'));
  } else {
    dispatch(failure(response.data.message));
  }
};

export const createViewProofForOrder = (title, payload, component = 'order') => async (dispatch) => {
  const response = component === 'admin-order' ? await getViewProofForAdminOrder(payload) : await getViewProofForOrder(payload);
  const binaryData = atob(response.data.data.base64);

  if (response.status === 200) {
    // Create a Uint8Array from the binary data
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    // Create an Object URL for the Blob
    const url = URL.createObjectURL(blob);
    downloadPDF(title, url);
    dispatch(success('Download Proof generated successfully'));
  } else {
    dispatch(failure(response.data.message));
  }
};


export const downloadPDF = (title, url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = title + '.pdf';

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

export const drawRestrictedAreaOnPage = (store, product, envelopeType) => {
  if (addressPrinting[`${product.productType}-${envelopeType}`]) {
    if (product.productType === 'Professional Letters') {
      addAreaToProfessionalLetters(store, Barcode);
    } else if (product.productType === multiPageLetters[0]) {
      addRestrictedAreaToPostCard(
        store,
        [3.2835, 2.375],
        Barcode,
        product.productType === 'Postcards',
      );
    } else if (product.productType === multiPageLetters[1]) {
      addRestrictedAreaToTriFold(store, [3.2835, 2.375], Barcode);
    } else if (product.productType === multiPageLetters[2]) {
      addRestrictedAreaToBiFold(store, [3.2835, 2.375], Barcode);
    }
  }
};


export const addElementsforRealPennedLetters = (store) => {
  const page = store.pages[0];

  page.addElement({
    id: 'header',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: true,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: 25,
    y: 130,
    width: 480,
    height: 40,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: true,
    styleEditable: false,
    text: "Hi ((C.FIRST_NAME)),",
    fontSize: 20,
    fontFamily: 'lexi Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'blue',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.75,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });



  page.addElement({
    id: 'content',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: true,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: 25.499999994998234,
    y: 220.60449354986338,
    width: 477,
    height: 400,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: true,
    styleEditable: false,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In recent projects, we've focused heavily on OLC to enhance digital engagement. Real Penned Letters, is a unique initiative.\n\nAs we continue to develop OLC platforms, we remain committed to integrating elements like Real Penned Letters to enhance digital and personal communication. \n",
    fontSize: 20,
    fontFamily: 'lexi Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'blue',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.75,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });


  page.addElement({
    id: 'footer',
    type: 'text',
    name: '',
    opacity: 1,
    visible: true,
    selectable: true,
    removable: false,
    alwaysOnTop: true,
    showInExport: true,
    x: 165.49999999499846,
    y: 575.7346179851188,
    width: 335,
    height: 75,
    rotation: 0,
    animations: [],
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    sepiaEnabled: false,
    grayscaleEnabled: false,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    draggable: false,
    resizable: false,
    contentEditable: true,
    styleEditable: false,
    text: "Regards,\nYour Name",
    fontSize: 20,
    fontFamily: 'lexi Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: '',
    fill: 'blue',
    align: 'start',
    verticalAlign: 'top',
    strokeWidth: 0,
    stroke: 'black',
    lineHeight: 1.75,
    letterSpacing: 0,
    backgroundEnabled: false,
    backgroundColor: '#7ED321',
    backgroundOpacity: 1,
    backgroundCornerRadius: 0.5,
    backgroundPadding: 0.5,
  });
  
  store.history.clear();
}