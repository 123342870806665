import React, { useEffect, useState } from "react";

// Hooks
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { createViewProofForOrder } from "../../../utils/template-builder";
import { failure, success } from "../../../redux/actions/snackbar-actions";
import {
  cancelOrder,
  fetchOrders,
  payAndProcess,
} from "../../../redux/actions/orders-actions";
import { getUserData } from "../../../redux/actions/auth-actions.js";

//Utils
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION,
} from "../../../utils/constants";
import { balanceFormatter } from "../../../utils/helperFunctions";
import {
  dateFormat,
  doesItFallInCancellationPeriod,
} from "../../../utils/date-format";

// Components
import Loader from "../../General/Loader";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import AddFunds from "../AddFunds";

// MUI Table
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Stack,
  CircularProgress,
  Menu,
  Checkbox,
  IconButton,
} from "@mui/material";
// Icons
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PaymentIcon from "../../../assets/images/orders/payment-icon.svg";
import ProofIcon from "../../../assets/images/orders/proof-icon.svg";
import CancelIcon from "../../../assets/images/orders/cancel-icon.svg";
import CancelOrderIcon from "../../../assets/images/orders/cancelOrder.svg";
import PayAndProcessIcon from "../../../assets/images/orders/processOrder.png";
import InsufficientIcon from "../../../assets/images/orders/insufficient.svg";
import CancelDisabled from "../../../assets/images/orders/cancelOrderDisabled.svg";
import InfoIcon from "../../../assets/images/webhooks/info.svg";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Styles
import "./styles.scss";
import { MESSAGES } from "../../../utils/message";

//moment
import moment from "moment";

const OrderTable = ({
  pagination,
  updatePagination,
  setSelectedRows,
  selectedRows,
}) => {
  const [downloadingProof, setDownloadingProof] = useState({
    status: false,
    rowId: null,
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [removeBulkSelect, setRemoveBulkSelect] = useState(false);
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    loading: false,
    currentAction: null,
    params: null,
  });
  const [fundsModal, setFundsModal] = useState({ open: false, params: null });
  const [activeRowId, setActiveRowId] = useState(null);

  const pageSizes = DEFAULT_PAGE_SIZES;
  const open = Boolean(anchorEl);

  const fetchedRows = useSelector((state) => state.ordersReducers.orders);
  const rowsCount = useSelector((state) => state.ordersReducers.rowsCount);
  const to = useSelector((state) => state.ordersReducers.to);
  const from = useSelector((state) => state.ordersReducers.from);
  const lastPage = useSelector((state) => state.ordersReducers.lastPage || 1);
  const currentPage = useSelector(
    (state) => state.ordersReducers.currentPage || 1
  );
  const perPage = useSelector(
    (state) => state.ordersReducers.perPage || DEFAULT_PAGINATION.pageSize
  );
  const isLoading = useSelector((state) => state.ordersReducers.loading);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    setDialog({
      open: false,
      message: "",
      loading: false,
      params: null,
      currentAction: null,
      subMessage: "",
      heading: "",
      successButtonName: "Yes",
    });
  };

  const handleOk = async () => {
    setDialog((prev) => ({ ...prev, loading: true }));
    if (dialog.currentAction === "cancel") {
      const payload = { orderId: dialog.params.id };
      const response = await dispatch(cancelOrder(payload));
      if (response.status === 200) {
        dispatch(success(response.data.message));
        dispatch(fetchOrders(pagination));
      } else {
        dispatch(failure(response.data.message));
      }
    } else if (dialog.currentAction === "payAndProcess") {
      const payload = { orderId: dialog.params.id };
      const response = await dispatch(payAndProcess(payload));
      if (response.status === 200) {
        dispatch(success(response.data.message));
        dispatch(getUserData());
        dispatch(fetchOrders(pagination));
      } else {
        if (
          response.data.message === "Auto Funding Failed" ||
          response.data.message == "Auto Funding is disabled"
        ) {
          setTimeout(() => {
            handleInsufficientFunds(dialog.params);
          }, 1000);
        } else {
          dispatch(failure(response.data.message));
        }
      }
    } else if (dialog.currentAction == "addFunds") {
      setFundsModal({ open: true, params: dialog.params });
    }
    setDialog((prev) => ({ ...prev, loading: false, open: false }));
  };

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

  const handleRowClick = (params, event) => {
    if (event.target.nodeName == "IMG") {
      return;
    }
    if (event.ctrlKey || event.metaKey) {
      window.open(`/orders/detail/overview/${params.id}`, "_blank");
    } else {
      navigate(`/orders/detail/overview/${params.id}`);
    }
  };

  const downloadViewProof = async (params) => {
    try {
      setDownloadingProof({
        status: true,
        id: params.id,
      });

      await dispatch(
        createViewProofForOrder(`Order-${params.id}`, {
          orderId: params.id,
        })
      );
      setDownloadingProof({
        status: false,
        id: null,
      });
    } catch (error) {
      setDownloadingProof({
        status: false,
        id: null,
      });
      dispatch(failure(error?.response?.data?.message || error?.message));
    }
  };

  const handleCancelOrder = async (params) => {
    setDialog({
      open: true,
      message: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MESSAGE,
      loading: false,
      currentAction: "cancel",
      params: params,
      successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUCCESS_BUTTON,
      subMessage: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUB_MESSAGE,
      heading: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.HEADING,
      icon: CancelOrderIcon,
    });
  };

  const handlePayAndProcessOrder = async (params) => {
    const currentDate = moment();
    setDialog({
      open: true,
      message: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.MESSAGE,
      loading: false,
      currentAction: "payAndProcess",
      params: params,
      successButtonName:
        MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.SUCCESS_BUTTON,
      subMessage: `${
        MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.SUB_MESSAGE
      } ${dateFormat(currentDate, true)}`,
      heading: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.HEADING,
      icon: PayAndProcessIcon,
    });
  };

  const handleInsufficientFunds = (params) => {
    setDialog({
      open: true,
      message: MESSAGES.ORDERS.DETAIL.MODALS.ADD_FUNDS.MESSAGE,
      loading: false,
      currentAction: "addFunds",
      params: params,
      successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.ADD_FUNDS.SUCCESS_BUTTON,
      subMessage: MESSAGES.ORDERS.DETAIL.MODALS.ADD_FUNDS.SUB_MESSAGE,
      heading: MESSAGES.ORDERS.DETAIL.MODALS.ADD_FUNDS.HEADING,
      icon: InsufficientIcon,
    });
  };

  const selectAll = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: true }));
    setRows(updatedRows);
    setSelectedRows([-1]);
  };

  const selectVisble = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: true }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((contact) => contact.id);
    setSelectedRows(selectedRowIds);
  };

  const selectNone = () => {
    setBulkSelect(false);
    setRemoveBulkSelect(false);
    const updatedRows = rows.map((contact) => ({ ...contact, checked: false }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const selectRow = (event, selectedRow) => {
    event.stopPropagation();

    // Update the selectedRow object directly in the rows array
    const updatedRows = rows.map((row) =>
      row.id === selectedRow.id ? { ...row, checked: !row.checked } : row
    );

    // If the user selects all contacts and then removes a single contact, update the selected rows array accordingly.
    if (selectedRows[0] === -1) {
      const filteredRows = rows.map((row) =>
        row.id === selectedRow.id
          ? { ...row, checked: !row.checked }
          : { ...row, checked: true }
      );

      const filteredRowsIds = filteredRows
        .map((contact) => contact.checked === true && contact.id)
        .filter(Boolean);

      setSelectedRows(filteredRowsIds);
      setRows(updatedRows);
      setRemoveBulkSelect(true);
      return;
    }

    setRows(updatedRows);

    const selectedRowIds = selectedRows.includes(selectedRow.id)
      ? selectedRows.filter((id) => id !== selectedRow.id) // Remove the ID if already selected
      : [...selectedRows, selectedRow.id]; // Add the ID if not selected

    // Update the selectedRows state with the new array
    setSelectedRows(selectedRowIds);

    setRemoveBulkSelect(true);
  };

  const bulkSelection = () => {
    const newBulkSelect = !bulkSelect;
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((contact) => ({
      ...contact,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);

    const selectedRowIds = updatedRows.map((contact) => contact.id);
    newBulkSelect ? setSelectedRows(selectedRowIds) : setSelectedRows([]);
  };

  const removeBulkSelection = () => {
    const newBulkSelect = !removeBulkSelect;
    setRemoveBulkSelect(newBulkSelect);
    setBulkSelect(newBulkSelect);

    const updatedRows = rows.map((contact) => ({
      ...contact,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const [showActions, setShowActions] = React.useState(null);
  const openActions = Boolean(showActions);
  const handleClick = (event) => {
    event.stopPropagation();
    setShowActions(event.currentTarget);
  };
  const handleClose = () => {
    setShowActions(null);
  };

  const renderMenuItem = (activeRowId) => {
    const itemsArray = [];
    const hold = activeRowId?.status == "On Hold";
    const canceled = activeRowId?.status !== "Canceled";
    const scheduled = activeRowId?.status == "Scheduled";
    if (hold) {
      itemsArray.push(
        <MenuItem
          onClick={() => {
            handlePayAndProcessOrder(activeRowId);
            handleClose();
          }}
        >
          <img src={PaymentIcon} alt="pay&process" />
          Pay and Process Order
        </MenuItem>
      );
    }
    if (canceled) {
      itemsArray.push(
        <MenuItem
          onClick={() => {
            downloadViewProof(activeRowId);
            handleClose();
          }}
        >
          <img src={ProofIcon} alt="proof" />
          Download Proof
        </MenuItem>
      );
    }
    if (doesItFallInCancellationPeriod(activeRowId?.scheduledDate) && scheduled) {
      itemsArray.push(
        <MenuItem
          onClick={() => {
            handleCancelOrder(activeRowId);
            handleClose();
          }}
        >
          <img src={CancelIcon} alt="Cancel" />
          Cancel Order
        </MenuItem>
      );
    }
    if (itemsArray.length) {
      return itemsArray;
    }
    return false;
  };

  const columns = [
    {
      field: " ",
      headerName: (
        <Box className="checkboxHeader">
          {removeBulkSelect ? (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<RemoveIcon />}
              checked={removeBulkSelect}
              onClick={removeBulkSelection}
            />
          ) : (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<DoneIcon />}
              checked={bulkSelect}
              onClick={bulkSelection}
            />
          )}
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableRipple
            className="bulkActionsDropIcon"
          >
            <KeyboardArrowDownIcon
              sx={{
                color: anchorEl ? "#ed5c2f" : "inherit",
                transform: anchorEl && "rotate(180deg)",
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            MenuListProps={{
              className: "bulkMenuOptions",
            }}
          >
            <MenuItem
              onClick={rowsCount > 0 ? selectAll : undefined}
              className={selectedRows[0] === -1 ? "highlight" : ""}
            >
              {MESSAGES.CONTACTS.SELECT_ALL_ITEMS} ({rowsCount})
            </MenuItem>
            <MenuItem onClick={selectNone}>
              {MESSAGES.CONTACTS.SELECT_NONE}
            </MenuItem>
            <MenuItem onClick={selectVisble}>
              {MESSAGES.CONTACTS.SELECT_VISIBLE_ITEMS}
            </MenuItem>
          </Menu>
        </Box>
      ),
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <Box className="checkboxRow">
          <Checkbox
            onClick={(event) => selectRow(event, params.row)}
            className="bulkCheck"
            icon={<Box className="bulkNotchecked" />}
            checkedIcon={<DoneIcon />}
            checked={params.row.checked}
          />
        </Box>
      ),
    },
    {
      field: "id",
      headerName: "Order ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.id}</Typography>
        </Box>
      ),
    },
    {
      field: "date",
      headerName: "Order Date",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.date}</Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Order Status",
      type: "number",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.status}</Typography>
        </Box>
      ),
    },
    {
      field: "source",
      headerName: "Source",
      width: 150,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.source}</Typography>
        </Box>
      ),
    },
    {
      field: "productType",
      headerName: "Product Type",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.productType}</Typography>
        </Box>
      ),
    },
    {
      field: "deliverableQuantity",
      headerName: "Quantity",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.deliverableQuantity}</Typography>
        </Box>
      ),
    },
    {
      field: "cost",
      headerName: "Cost",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{balanceFormatter(params.row.cost)}</Typography>
        </Box>
      ),
    },
    {
      field: "scheduledDate",
      headerName: (
        <Tooltip title="The date mailers will be sent to our mailhouse.">
          <Box display="flex" alignItems="center" gap={1}>
            <Typography className="headerName">Scheduled Date</Typography>
            <img src={InfoIcon} alt="icon" />
          </Box>
        </Tooltip>
      ),
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.scheduledDate}</Typography>
        </Box>
      ),
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.paymentStatus}</Typography>
        </Box>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1500 ? 1 : 0,
      renderCell: (params) => (
        <>
          {window.innerWidth >= 1380 ? (
            <Box className="actionsWrapper">
              {params.row.status == "On Hold" ? (
                <Tooltip title="Pay and process order">
                  <img
                    src={PaymentIcon}
                    alt="Pay"
                    onClick={() => handlePayAndProcessOrder(params)}
                  />
                </Tooltip>
              ) : (
                <Box className="placeholderOrders" />
              )}
              {params.row.status !== "Canceled" ? (
                <Tooltip title="Download Proof">
                  {downloadingProof.status &&
                  downloadingProof.id === params.row.id ? (
                    <CircularProgress
                      sx={{
                        color: "#ED5C2F",
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    />
                  ) : (
                    <img
                      src={ProofIcon}
                      alt="Proof"
                      onClick={() => downloadViewProof(params)}
                    />
                  )}
                </Tooltip>
              ) : (
                <Box className="placeholderOrders" />
              )}
              <Tooltip
                title={
                  doesItFallInCancellationPeriod(params.row.scheduledDate) &&
                  (params.row.status == "On Hold" ||
                    params.row.status == "Scheduled")
                    ? "Cancel Order"
                    : "Cancellation window has expired"
                }
              >
                {doesItFallInCancellationPeriod(params.row.scheduledDate) &&
                (params.row.status == "On Hold" ||
                  params.row.status == "Scheduled") ? (
                  <img
                    src={CancelIcon}
                    alt="Cancel"
                    onClick={() => handleCancelOrder(params)}
                  />
                ) : (
                  <img src={CancelDisabled} alt="Cancel" />
                )}
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Tooltip
                title={
                  renderMenuItem(params.row) ? null : "No Actions Available"
                }
              >
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openActions ? "long-menu" : undefined}
                  aria-expanded={openActions ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => {
                    renderMenuItem(params.row)
                      ? setActiveRowId(params.row)
                      : undefined;
                    renderMenuItem(params.row)
                      ? handleClick(event)
                      : event.stopPropagation();
                  }}
                  disableRipple={renderMenuItem(params.row) ? false : true}
                  sx={{
                    cursor: renderMenuItem(params.row)
                      ? "pointer"
                      : "not-allowed",
                    opacity: renderMenuItem(params.row) ? "1" : "0.5",
                  }}
                >
                  <MoreVertIcon
                    sx={{
                      color:
                        activeRowId?.id === params.row.id && openActions
                          ? "#ed5c2f"
                          : "inherit",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={showActions}
                open={openActions}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxWidth: "160px",
                  },
                  className: "orderActionsDropdown",
                }}
              >
                {renderMenuItem(activeRowId)}
              </Menu>
            </Box>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setSelectedRows([]);
  }, [currentPage, perPage]);

  useEffect(() => {
    !selectedRows.length && setRemoveBulkSelect(false);
    (rows.length && selectedRows.length === rows.length) ||
    selectedRows[0] === -1
      ? (setBulkSelect(true), setRemoveBulkSelect(false))
      : setBulkSelect(false);
  }, [selectedRows, rows]);

  useEffect(() => {
    setRows(fetchedRows);
  }, [fetchedRows]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "25px",
        width: "100%",
      }}
      className="orderstableWrapper"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        rowCount={rowsCount}
        pagination={pagination}
        paginationMode="server"
        onRowClick={handleRowClick}
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="ordersTableGrid"
        components={{
          NoRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              No results with current filters.
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="paginationWrapper">
        <Pagination
          count={lastPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Select
          className={
            perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
          }
          value={perPage}
        >
          {pageSizes.map((pageSize) => {
            return (
              <MenuItem
                key={pageSize}
                value={pageSize}
                onClick={() => {
                  updatePagination({
                    page: 0,
                    pageSize: pageSize,
                  });
                }}
              >
                {pageSize}
              </MenuItem>
            );
          })}
        </Select>

        <Typography>
          Showing {rowsCount ? from : 0} to {rowsCount < to ? rowsCount : to} of{" "}
          {rowsCount} results
        </Typography>
      </Box>
      {dialog.open && (
        <ConfirmDialog
          dialog={dialog}
          open={dialog.open}
          loading={dialog.loading}
          handleClose={handleCloseDialog}
          handleOk={handleOk}
        />
      )}

      {fundsModal.open && (
        <AddFunds
          params={fundsModal.params}
          open={fundsModal.open}
          handleOpen={() => {
            setFundsModal((prev) => ({ ...prev, open: true }));
          }}
          handleClose={() => {
            setFundsModal((prev) => ({ ...prev, open: false }));
          }}
        />
      )}
    </div>
  );
};

export default OrderTable;
