import React, { useState } from "react";

//hooks
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//actions
import { failure, success } from "../../../../../redux/actions/snackbar-actions";
import { cancelOrder, payAndProcess } from "../../../../../redux/actions/orders-actions"
import { getUserData } from "../../../../../redux/actions/auth-actions.js";

//utils
import { dateFormat, getTimeOnly } from "../../../../../utils/date-format";
import { MESSAGES } from "../../../../../utils/message";
import { createViewProofForOrder } from "../../../../../utils/template-builder";

//Mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid, CircularProgress } from "@mui/material";
import ConfirmDialog from "../../../ConfirmDialog/ConfirmDialog";
import AddFunds from "../../../AddFunds";

//icons
import PayAndProcessIcon from "../../../../../assets/images/orders/processOrder.png"
import CancelOrderIcon from "../../../../../assets/images/orders/cancelOrder.svg"
import InsufficientIcon from "../../../../../assets/images/orders/insufficient.svg"


//moment
import moment from 'moment'

// styles
import "./styles.scss";


function HoldStepper({ info, refreshOverview }) {
  const [activeStep, setActiveStep] = useState(1);

  const [proofLoading, setProofLoading] = useState(false);

  const [fundsModal, setFundsModal] = useState({open: false, params: null})

  const [dialog, setDialog] = useState({ open: false, message: '', loading: false, currentAction: null, params: null });

 const {id} = useParams() 
 const dispatch = useDispatch();


  const downloadViewProof = async () => {
    try {
      setProofLoading(true)
      await dispatch(
        createViewProofForOrder(`Order-${id}`, {
          orderId: id,
        })
      );
      setProofLoading(false)
    } catch (error) {
      dispatch(failure(error?.response?.data?.message || error?.message));
      setProofLoading(false)
    }
  };

  const handleCloseDialog = () => {
    setDialog({ open: false, message: '', loading: false, params: null, currentAction: null });
  }

  const handleOk = async () => {
    setDialog((prev) => ({ ...prev, loading: true }));
    if(dialog.currentAction === 'cancel') {
      const payload = {orderId : id}
      const response = await dispatch(cancelOrder(payload));
      if (response.status === 200) {
        dispatch(success(response.data.message));
        refreshOverview();
      } else {
        dispatch(failure(response.data.message));
      }
    } else if(dialog.currentAction === 'payAndProcess') {
      const payload = {orderId : id}
      const response = await dispatch(payAndProcess(payload));
      if (response.status === 200) {
        dispatch(getUserData());
        dispatch(success(response.data.message));
        refreshOverview();
      } else {
        if(response.data.message === "Auto Funding Failed" || response.data.message =="Auto Funding is disabled") {
          setTimeout(() => {
          handleInsufficientFunds(dialog.params)
          }, 1000)
        }else {
        dispatch(failure(response.data.message));
        }
      }
    } else if(dialog.currentAction == "addFunds") {
      setFundsModal({open : true, params: {row: {id, cost: info.cost }}});
    }
    
    setDialog((prev) => ({ ...prev, loading: false, open: false }));

  }

  const handleCancelOrder = async ()=> {
   
    setDialog({ open: true, 
      message: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MESSAGE, 
      loading: false, 
      currentAction: 'cancel', 
      successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUCCESS_BUTTON,
      subMessage: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUB_MESSAGE,
      heading: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.HEADING,
      icon: CancelOrderIcon
     })
  }

  const handlePayAndProcessOrder = async ()=> {
    let currentDate = moment();
    setDialog({ open: true, 
      message: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.MESSAGE, 
      loading: false, 
      currentAction: 'payAndProcess', 
      successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.SUCCESS_BUTTON,
      subMessage: `${MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.SUB_MESSAGE} ${dateFormat(currentDate, true)}`,
      heading: MESSAGES.ORDERS.DETAIL.MODALS.PAY_AND_PROCESS.HEADING,
      icon: PayAndProcessIcon
     })
  }


  const handleInsufficientFunds = () => {
    setDialog({ open: true, 
        message: MESSAGES.ORDERS.DETAIL.MODALS.ADD_FUNDS.MESSAGE, 
        loading: false, 
        currentAction: 'addFunds', 
        successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.ADD_FUNDS.SUCCESS_BUTTON,
        subMessage: MESSAGES.ORDERS.DETAIL.MODALS.ADD_FUNDS.SUB_MESSAGE,
        heading: MESSAGES.ORDERS.DETAIL.MODALS.ADD_FUNDS.HEADING,
        icon: InsufficientIcon
     })
}
  const steps = [
    {
      name: "Scheduled",
      date: `${dateFormat(info.createdAt, true)}`,
      time: getTimeOnly(info.createdAt)
    },
    {
      name: info.status,
      subTxt: info.paymentStatus,
      date: `${dateFormat(info.updatedAt, true)}`,
      time: getTimeOnly(info.updatedAt)
    },
  ];


  return (
    <Box className="hold_stepper_wrapper" sx={{ width: "100%" }}>
      <Grid container>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className="main_layer">
            <div className="hold-custom-stepper ">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className={`step ${index < activeStep ? "completed" : ""} ${index === activeStep ? "active" : ""
                    }`}
                >
                  <div className="stepOuter">
                    <div className="upertxt">
                      <div className="step-text-top">{step.name}</div>
                      <div className="step-subtext-top">{step.subTxt}</div>
                    </div>
                    <div className="bullet"></div>
                    <div className="bottomtxt">
                      <div>{step.date}</div>
                      <div>{step.time}</div>
                    </div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`${index < activeStep ? "completed" : ""}`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
       {info.status !== "Canceled" &&   <div className="btn_outer">
             <Button onClick={() => handleCancelOrder()}>{MESSAGES.ORDERS.DETAIL.OVERVIEW.CANCEL_ORDER_BUTTON}</Button> 
             {proofLoading ? 
          <Button disabled style={{width: 97}}>
          <CircularProgress
                sx={{
                  color: "#ED5C2F",
                  width: "20px !important",
                  height: "20px !important",
                }}
              /></Button> :  <Button onClick={() => downloadViewProof()}>{MESSAGES.ORDERS.DETAIL.OVERVIEW.VIEW_PROOF_BUTTON}</Button>}
            <Button onClick={() => handlePayAndProcessOrder()} className="activeBtn">{MESSAGES.ORDERS.DETAIL.OVERVIEW.PAY_AND_PROCESS_BUTTON}</Button>
          </div>
          }
        </Grid>
      </Grid>
      {
        dialog.open && <ConfirmDialog dialog={dialog} open={dialog.open} loading={dialog.loading} handleClose={handleCloseDialog} handleOk={handleOk} />
      }
      { fundsModal.open && <AddFunds
        params={fundsModal.params}
        open={fundsModal.open}
        refreshOverview={refreshOverview}
        handleOpen={() => {
          setFundsModal((prev) => ({...prev , open: true}));
        }}
        handleClose={() => {
          setFundsModal((prev) => ({...prev , open: false}));
        }}
        />}
    </Box>
  );
}
export default HoldStepper;
